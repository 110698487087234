@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































































@import '@/assets/scss/_variables.scss';

/* scroll-style */
* {
  scrollbar-color: #00ffff #001023;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}
*::-webkit-scrollbar-track {
  background: #001023;
}
*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #00ffff;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 40px;
  padding: 6px 22px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  border-radius: 9px;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s;

  @media only screen and (min-width: $tablet-width) {
    padding: 8px 32px;
    min-height: 50px;
    font-size: 16px;
    line-height: 20px;
  }

  &.lightblue {
    font-weight: 700;
    color: #fff;
    background: #00f3f4;
    border-color: #00ffff;
    &:hover {
      background: #00ffff;
      box-shadow: 0 0 8px 5px rgba(0, 255, 255, 0.1);
    }
  }

  &.transp {
    background: rgba(0, 224, 255, 0.1);
    color: #00ffff;
    border-color: #00ffff;
    &:hover {
      background: rgba(0, 224, 255, 0.3);
    }
  }
}
