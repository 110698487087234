@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
@import '@/assets/scss/_variables.scss';

.Landing_footerMenu {
  max-width: 1650px;
  margin: 0 auto;
  padding: 25px 16px;
  display: flex;
  justify-content: space-between;
  .Landing_footerMenuContainer--middle & {
    padding: 0 16px 25px;
    @media only screen and (min-width: $desktop-width) {
      padding: 25px 16px;
    }
  }
  @media only screen and (min-width: $desktop-width) {
    padding: 24px 16px 65px;
  }
}
.Landing_footerMenuLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  @media only screen and (min-width: $tablet-width) {
    padding: 0 15px 0 0;
  }
  .Landing_footerMenuContainer--middle & {
    padding: 0;
  }
}
.Landing_footerMenuContainer {
  border-top: solid 1px #0F1D41;
  &--middle {
    border-top: none;
  }
}
.Landing_footer {
  background: #001023;
  position: relative;
  z-index: 1;
  text-align: left;
  @include landingMontserratFont;
}
.Landing_footerWrapper {
  max-width: 1644px;
  margin: 0 auto;
  padding: 40px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 10px;  
  grid-template-areas: 'footerItem1 footerItem1' 'footerItem2 footerItem3' 'footerItem4 footerItem4';
  .footerItem1 {
    grid-area: footerItem1;
    margin-bottom: 20px;
    @media only screen and (min-width: $tablet-width) {
      margin-bottom: 0;
    }
  }
  .footerItem2 {
    grid-area: footerItem2;
  }
  .footerItem3 {
    grid-area: footerItem3;
  }
  .footerItem4 {
    grid-area: footerItem4;
  }
  @media only screen and (min-width: $tablet-width) {
    gap: 0px 20px; 
    grid-template-columns: 1fr 1fr 1fr .94fr;
    grid-template-areas: 'footerItem1 footerItem2 footerItem3 footerItem4';
  }
  @media only screen and (min-width: $desktop-width) {
    padding: 76px 16px 31px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.Landing_footerTitle {
  color: #00FFFF;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 7px 0 16px 0;
}
.Landing_footerLink {
  // @include linkHoverLine;
  margin: 0 0 12px 0;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  transition: all .25s;
  &:before {
    // background: #fff;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: $mobile-width) {
    font-size: 14px;
    line-height: 20px;
  }
  .icon {
    --side: 20px;
    display: block;
    width: var(--side);
    height: var(--side);
    margin: 0 5px 0 0;
    object-fit: scale-down;
    object-position: 50% 50%;
  }
}

a.Landing_footerLink {
  &:hover {
    color: #00FFFF;
  }
}

.Landing_footerLinkSocContainer {
  display: flex;
  margin-bottom: 22px;
}
.Landing_footerLinkSoc {
  position: relative;
  --side: 40px;
  width: var(--side);
  height: var(--side);
  margin-right: 21px;
  background: rgba(0, 255, 255, .1);
  border-radius: 8px;
  &:last-child {
    margin-right: 0;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: scale-down;
    object-position: 50% 50%;
  }
}
.Landing_footerMenu {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: $tablet-width) {
    flex-wrap: nowrap;
  }
}
.Landing_footerMenuItem {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  padding-right: 20px;
  margin: 4px 0;
  transition: all .25s;
  @media only screen and (min-width: $tablet-width) {
    &:after {
      --side: 6px;
      content: "";
      display: block;
      position: relative;
      top: -1px;
      width: var(--side);
      height: var(--side);
      background: #00FFFF;
      margin-left: 20px;
      transform: rotate(-45deg);
    }
    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
}
a.Landing_footerMenuItem {
  &:hover {
    color: #00FFFF;
  }
}