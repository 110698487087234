@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

html {
  // fix on landing page
  // overflow: hidden !important;
}

html, body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  &:not(.dark) {
    .PasswordChange_wrapper {
      .text-field-input-root {
        button {
          color: #a6aebf;
        }
      }
    }

    input::placeholder {
      color: #a6aebf !important;
    }

    .apiKey_box-inner {
      background-image: url('assets/img/_common/lock-img-light.svg');
    }

    .Api_button {
      background-color: rgba(#50C5E9, 0.21);

      .Api_button-text {
        color: #2d4d70;
      }

      .Api_button-box {
        background-color: #50C5E9;
      }

      &:hover {
        background-color: rgba(#50C5E9, 0.5);

        .Api_button-text {
          color: #2d4d70;
        }

        .Api_button-box {
          color:  #2d4d70;
        }
      }
    }

    .tabs {
      .tab {
        color: #0F1E30;
        border: solid 1px #50C5E9;

        &:not(.router-link-active):hover {
          background-color: #50C5E9;
          color: #fff;
          border-color: #50C5E9;
        }

        &.router-link-active {
          color: #fff;
          background-color: #50C5E9;
        }
        &:first-child {
          border-left: solid 1px #50C5E9;
        }

        &:last-child {
          border-right: solid 1px #50C5E9;
        }
      }
    }
    .theme--dark.v-input input,
    .theme--dark.v-input textarea,
    .theme--dark.v-label--is-disabled {
      color: #a6aebf !important;
    }
    .theme--dark.v-application {
      color: #a6aebf !important;
    }

    .Checkbox_checkmark {
      border: 1px solid #E8ECF3;
    }

    .navigation-container-menu-title {
      &--soon {
        &:before {
          content: 'coming soon';
          color: #50C5E9;
        }
      }
    }

    .navigation-container-menu-title {
      &--soon {
        &:before {
          content: 'coming soon';
          color: #50C5E9;
        }
      }
    }

    // Fees and Limits start light page tabs
    .tabs {
      .tab-item {
        color: #22272F;

        &:not(.router-link-active):hover,
        &.router-link-active {
          background-color: #50C5E9;
          color: #fff;
        }
      }
    }
    // Fees and Limits end light page tabs
    .extra-filter-arrow {
      color: #50C5E9;
    }

    .ExtraFilter {
      &_arrow {
        color: #50C5E9;
      }
    }
    // Switcher component start light theme
    .custom-check {
      .switch:after,
      .switch-one:after {
        background-color: #fff;
        box-shadow: 0 10px 21px rgba(#22272F, .24);
      }
    }
    // Switcher component end light theme

    // Text field input start light theme
    .date-picker-input {
      // background-color: #fff;
      //border: 1px solid #E8ECF3;
      box-shadow: none !important;

      input {
        color: #a6aebf !important;
      }
    }

    .text-field-input-root {
      .v-label {
        color: #A6AEBF;
      }
      .v-input.v-input--dense.v-text-field {
        .v-input__control {
          .v-input__slot {
            border: 1px solid #E8ECF3;
          }
        }
      }
    }

    .TextFieldInput_dropdownArrow {
      color: #50C5E9;
    }
    // Text field input end light theme

    // Exchange tabs components start light theme
    .exchange-heading {
      .exchange-tab {
        text-shadow: none;
        color: #2D4D70;

        &.exchange-tab-active {
          background-color: #50C5E9;
          color: #fff;
        }
      }
    }
    // Exchange tabs components end light theme

    // Currency icon components start light theme
    .CurrencyIcon {
      &_icon {
        color: #0F1E30;
        background-color: #50C5E9;
      }
    }
    // Currency icon components end light theme

    // Select components start light theme
    .select_root.v-select.v-text-field--solo {
      & > .v-input__control {
        & > .v-input__slot {
          .v-input__append-inner {
            .v-input__icon.v-input__icon--append {
              .v-icon.theme--dark {
                color: #50C5E9 !important;
              }
            }
          }
        }
      }
    }
    // Select components end light theme

    // Square coin icon components start light theme
    .SquareCoinIcon_wrapper {
      color: #0F1E30;
      background-color: #50C5E9;
    }

    .SquareCoinIcon_root {
      background: rgba(#50C5E9, 0.1);
    }
    // Square coin icon components end light theme

    // Outline button components start light theme
    .outline-button-default {
      color: #50C5E9;
      border: 2px solid #50C5E9;
      background-color: #0F1E30;

      &:not(.outline-button-disabled, .outline-button-red) {
        &:hover {
          background-color: #50C5E9;
          box-shadow: 0 0 10px rgba(#50C5E9, .55);
          color: #fff;
        }
        &:active {
          background: rgba(#50C5E9, .2);
        }
      }
    }
    // Outline button components end light theme

    // Currency filter start light theme
    .CurrencyFilter {
      .ExtraFilter_drowDown {
        background-color: #FBFBFB;
      }

      &_currencyList {
        background-color: #FBFBFB;
      }
    }
    // Currency filter components end light theme

    // History row start components light theme
    .history-row-wrapper {
      &.active-row {
        .history-row {
          background-color: #0F1E30;

          .history-row-col--type,
          .history-row-col {
            color: #fff;
          }
        }

        .round-arrow-btn {
          background-color: #50C5E9 !important;
          color: #fff;
        }
      }
    }

    .history-row-col--type,
    .history-row-col {
      color: #0F1E30;
    }
    // History row components end light theme

    // Date picker components start light theme
    .DatePicker_root {
      .DatePicker_wrapper {
        background-color: #FBFBFB;

        .v-picker {
          &.v-picker--date {
            &.theme--dark {
              .v-picker__body {
                background-color: #FBFBFB;

                .theme--dark.v-btn {
                  color: #50C5E9;
                }
              }
            }
          }
        }

        .DatePicker_heading {
          background-color: #FBFBFB;
        }
      }
    }

    .theme--dark {
      &.v-date-picker-header {
        .v-date-picker-header__value {
          &:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
            color: #50C5E9;
          }
        }
      }

      &.v-date-picker-table th,
      &.v-date-picker-table .v-date-picker-table--date__week {
        color: #50C5E9;
      }
    }

    // Exchange market components start light theme
    .exchange-balance {
      border-top: 1px dashed rgba(#22272F, 0.10);
    }
    // Exchange market components end light theme

    // Date picker

    .v-menu__content.theme--dark.menuable__content__active {
      .v-list.v-select-list.v-sheet.theme--dark {
        background-color: #fff;

        .v-list-item.theme--dark {
          .v-list-item__content {
            .v-list-item__title {
              color: #0F1E30 !important;
            }
          }

          &.v-list-item--active {
            background-color: rgba(#50C5E9, 0.5);

            .v-list-item__content {
              .v-list-item__title {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

body {
  .theme--dark {
    &.v-application {
      background-color: #F9F9F9 !important;
    }

    &.v-select {
      .v-select__selections {
        color: #A6AEBF !important;
      }
    }
  }

  &.dark {
    .theme--dark {
      &.v-application {
        background: $primaryBodyGradientBg !important;
      }

      &.v-select {
        .v-select__selections {
          color: #fff !important;
        }
      }
    }

    .select_root.v-select.v-text-field--solo {
      & > .v-input__control {
        & > .v-input__slot {
          background-color: rgba(#fff, 0.04);
          border: 1px solid transparent;

          .v-input__append-inner {
            .v-input__icon.v-input__icon--append {
              .v-icon.theme--dark {
                color: #fff !important;
              }
            }
          }
        }
      }
    }

    .tabs {
      .tab-item {
        color: $primaryLanding;

        &:not(.router-link-active):hover {
          background-color: #a5ffff;
          color: $primaryComponentWrapperBg;
        }

        &.router-link-active {
          background-color: #2af3f3;
          color: $primaryComponentWrapperBg;
        }
      }
    }
  }
}

.v-application {
  .v-application--wrap {
    display: block;
    overflow: hidden;
  }
  .v-messages {
    min-height: calc(1.2em + 8px);
    @media only screen and (min-width: $tablet-width) {
      font-size: 14px;
    }
    &__message {
      line-height: 1.2;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.v-menu__content.menuable__content__active {
  @include scrollbar;
}

.app_bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 9999;
  .app_bar-wrapper {
    background: rgba(42, 243, 243, 0.5);
    padding: 10px 0;
    .app_bar-btn {
      width: 70px;
      font-size: 16px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 4px;
      outline: none;
      box-shadow: none;
      text-align: center;
      line-height: 22px;
      margin-left: 10px;
    }
    a {
      color: #fff;
    }
  }
}

.InputRadioCirle {
  --diametr: 18px;
  width: var(--diametr);
  height: var(--diametr);
  border-radius: 50%;
  border: 1px solid #2AF3F3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  &:before{
    content: "";
    --diametr: 6px;
    width: var(--diametr);
    height: var(--diametr);
    border-radius: 50%;
    background: #2AF3F3;
    box-shadow: 0px 0px 8.18182px rgba(42, 243, 243, 0.55);
    opacity: 0;
  }
  input:checked + & {
    &:before {
      opacity: 1;
    }
  }
}

.requireStar {
  color: #2af3f3;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: #2d4d70;
    color: #2af3f3;
    border-radius: 4px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #2d4d70;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.v-ripple__container {
  display: none !important;
}

