@import '@/assets/scss/_variables.scss';

.OutlineButton_button {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  outline: none;
  border: none;
  cursor: pointer;
  border-radius: $default-border-radius;

  @media only screen and (min-width: $large-width) {
    font-size: 14px;
    line-height: 16px;
  }

  &.OutlineButton_fullWidth {
    width: 100%;
  }

  &--currency {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 3px;
    align-items: center;
    justify-content: center;
  }
}

.OutlineButton_defaultBtn {
//   line-height: 40px;
  line-height: 42px;
  color: #2af3f3;
  background: rgba(#2af3f3, 0.2);
  transition: 0.25s all ease-in-out;
  border: 2px solid #2af3f3;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.25s all ease-in-out;
  &.OutlineButton_red {
    background: rgba(255, 85, 109, 0.2);
    border: 2px solid #ff556d;
    color: #ff556d;
  }
  &.OutlineButton_disabled {
    border-color: #9395ab;
    color: #9395ab;
    background: rgba(#2af3f3, 0.1);
    cursor: not-allowed;
  }
  &:not(.OutlineButton_disabled, .OutlineButton_red) {
    @media only screen and (min-width: 768px) {
      &:hover {
        background: #2af3f3;
        box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
        color: #2d4d70;
      }
      &:active {
        background: rgba(#2af3f3, 0.2);
      }
    }
  }
}