@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































































@import '@/assets/scss/_colors.scss';
@import './Pay.scss';
.Invoice_title-back {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #2af3f3;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    background: #2af3f3;
    position: absolute;
    @media only screen and (min-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
  img {
    margin: 0 6px 0 2px;
    z-index: 2;
    position: relative;
    @media only screen and (min-width: 768px) {
      margin: 0 11px 0 5px;
    }
  }
}

.Invoice_title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
