@import '@/assets/scss/_mixins.scss';
.select-wrapper {
  &--inputSelect {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .error--text {
    .v-input__control {
      .v-input__slot {
        border: 1px solid #ff556d;
        background-color: rgba(255, 85, 109, 0.04);

        .v-input__append-inner {
          .v-input__icon {
            .v-icon {
              color: #ff556d;
            }
          }
        }
      }
    }

    .v-text-field__details{
      padding: 0;

      .v-messages__message {
        font-size: 12px;
        line-height: 14px;
        color: #ff556d;

        @media only screen and (min-width: $tablet-width) {
          font-size: 14px;
        }
      }
    }
  }
}

.select_root.v-select.v-text-field--solo {
  &.error--text  {
    & > .v-input__control {
      min-height: 46px;

      & > .v-input__slot {
        border-color: #ff556d !important;
      }
    }
  }

  & > .v-input__control {
    min-height: 46px;

    & > .v-input__slot {
      background-color: #fff;
      border: 1px solid #E8ECF3;
      box-shadow: none !important;

      .v-input__append-inner {
        .v-input__icon.v-input__icon--append {
          .v-icon.theme--dark {
            color: #50C5E9 !important;
          }
        }
      }
    }
  }

  &.select_root--inputSelect.v-select {
    max-width: 80px;

    & > .v-input__control {
      & > .v-input__slot {
        padding: 0;
        background: transparent;

        .v-select__selections {
          .v-select__selection {
            margin-right: 0;
            min-width: 50px;
            text-align: right;
          }

          input {
            display: none;
          }
        }

        .v-input__append-inner {
          padding-left: 0;
        }
      }
    }
  }
}

.v-menu__content.theme--dark.menuable__content__active {
  min-width: 76px;
  
  .v-list.v-select-list.v-sheet.theme--dark {
    background-color: #1f3347;
    text-align: left;

    .v-list-item.theme--dark {
      color: #fff;

      .v-list-item__content {
        .v-list-item__title {
          color: white !important;
          font-size: 15px;
        }
      }

      &.v-list-item--active {
        background-color: rgba(42, 243, 243, 0.1);

        .v-list-item__content {
          .v-list-item__title {
            color: white;
          }
        }
      }
    }
  }
}

.v-application .primary--text {
  color: #0f1f3f !important;
  caret-color: #0f1f3f !important;
}

.select-wallet-balance {
  .select_root.v-select.v-text-field--solo {
    & > .v-input__control {

      & > .v-input__slot {
        border: none !important;
        background-color: transparent !important;
        width: 110px;
      }
    }
  }
}

.invoicing-create-amount-field {
  position: absolute;
  right: 0;
  width: 100px;

  .select_root.v-select.v-text-field--solo {
    & > .v-input__control {

      & > .v-input__slot {
        background-color: transparent !important;
      }
    }
  }
}