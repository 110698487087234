@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.Landing_header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transition: 0.5s ease-in-out;
  @include landingMontserratFont;

  &_News {
    background: #0a1724;
    padding: 12px 12px 12px;
    color: #fff;
    &Content {
      word-break: break-word;
      font-size: 14px;
      line-height: 16px;
      p {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a { 
        color: inherit; 
      }
    }
    &Spoiler {
      &Body {
        .Landing_header_News--hide & {
          display: none;
          @media only screen and (min-width: $tablet-width) {
            display: block;
          }
        }
      }
      &Button {
        margin-top: 10px;
        @media only screen and (min-width: $tablet-width) {
          display: none;
        }
        .button {
          min-height: 40px;
          padding: 6px 22px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  &.Landing_header--sticky {
    @media only screen and (min-width: $desktop-width) {
      background: #0a1724;
    }

    .Landing_headerWrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .Landing_headerNav {
      margin-bottom: 0;
    }

    .Landign_headerNavLink {
      @media only screen and (min-width: $desktop-width) {
        color: #56c6e7 !important;
        &:before {
          background: #56c6e7 !important;
        }
      }
    }
  }

  .Landing_headerWrapper {
    max-width: 1671px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $primaryLanding;
    background: rgba(10, 23, 39, 0.8);
    transition: all .25s;
    padding: 20px 16px;

    @media only screen and (min-width: $desktop-width) {
      justify-content: space-between;
      background: none;
      border-bottom: none;
      flex-wrap: nowrap;
    }

    @media only screen and (min-width: $wide-width) {
      padding: 30px 16px 20px 58px;
    }
  }

  .Landign_headerLogo {
    height: 30px;
    margin-right: 30px;
    text-align: left;
    display: flex;
    align-items: center;

    @media only screen and (min-width: $desktop-width) {
      height: 50px;
      flex-grow: .7;
    }

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .Landing_headerNav {
    display: none;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;

    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    order: 10;
    flex-basis: 100%;
    flex-direction: column;
    margin: 10px 0 0;
    transition: all .25s;

    &.Landing_headerNavOpen {
      display: flex;
    }

    @media only screen and (min-width: $tablet-width) {
      flex-direction: row;
    }

    @media only screen and (min-width: $desktop-width) {
      justify-content: space-between;
      display: flex;
      margin-top: 0;
      flex-wrap: nowrap;
    }

    @media only screen and (min-width: $large-width) {
      position: inherit;
      background: none;
      width: auto;
      height: auto;
      flex-basis: auto;
      padding: 0;
      margin: 0 0 10px;
    }

    .Landign_headerNavLink {
      @include linkHoverLine;
      margin: 8px 10px;
      font-weight: 500;
      font-size: 12px;
      color: $primaryText;
      text-transform: uppercase;

      &:before {
        background-color: $primaryText;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .Landing_headerNavBtns {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 -5px;

      @media only screen and (min-width: $tablet-width) {
        flex-direction: row;
      }

      .button {
        margin: 8px 5px;
        min-width: 96px;
        min-height: 40px;
        padding: 6px 22px;
        font-size: 12px;
        line-height: 15px;
      }
      
    }
  }
}

.Landing_headerToggler {
  width: 20px;
  height: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  span {
    width: 100%;
    height: 2px;
    background: $primaryLanding;
  }

  &.Landing_headerTogglerOpen {
    justify-content: center;

    span {
      &:nth-child(1) {
        position: absolute;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        position: absolute;
        transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (min-width: $desktop-width) {
    display: none;
  }
}