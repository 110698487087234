.Pay-invoicing_root {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #14283f 0%, #050e1a 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 767px) {
    padding: 50px 10px;
  }
  .Pay-invoicing_orderInfo {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 767px) {
      max-width: 300px;
      margin: 0 auto;
    }
    .Pay-invoicing_orderItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      width: 33.33%;
      padding: 0 25px;
      @media only screen and (max-width: 767px) {
        font-size: 10px;
        padding: 0 25px 0 0;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 70%;
        }
        &:nth-child(3) {
          width: 100%;
          margin-top: 10px;
        }
      }
      &:last-child {
        text-transform: inherit;
      }
      &Heading {
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #5e6772;
        margin: 0 0 10px 0;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
        span {
          width: 28px;
          height: 28px;
          background: #071220;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px 0 0;
        }
      }
    }
  }
  .Pay-invoicing_wrapper {

    .Invoice_border {
      &.Invoice_borderTop {
        margin: 30px 0 30px 0;
      }
    }

    max-width: 880px;
    width: 100%;

    .Invoice_logo {
      height: 22px;
    }

    .Invoice_title {
      color: #fff;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 25px;
      font-size: 16px;
      margin: 30px 0 0 0;
      justify-content: flex-end;
      @media only screen and (min-width: 768px) {
        font-size: 21px;
        justify-content: center;
        margin: 50px 0 0 0;
      }
    }

    .Invoice_border {
      max-width: 880px;
      width: 100%;
      height: 1px;
      background: #2af3f3;
      opacity: 0.1;
      border-radius: 4px;
      margin: 10px 0 30px 0;
      @media only screen and (min-width: 768px) {
        margin: 20px 0 30px 0;
      }
    }

    .Invoice_inner {
      width: 100%;
      margin: 0 auto;
      &-amount {
        @media only screen and (min-width: 768px) {
          margin: 0 0 0 30px;
        }
      }
      &__pay {
          max-width: 300px;
          width: 100%;
          margin: 0 auto;
          @media only screen and (min-width: 768px) {
            margin: 0 0 0 auto;
            max-width: 220px;
          }
        &Nav {
          max-width: 300px;
          width: 100%;
          margin: 0 auto;
          display: flex;
          @media only screen and (min-width: 768px) {
            max-width: 195px;
            display: block;
          }
        }
        &Btn {
          height: 46px;
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          &:first-child {
            margin-right: 10px;
            @media only screen and (min-width: 768px) {
              margin-bottom: 10px;
              margin-right: auto;
            }
          }
        }
        &Description {
          text-align: left;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.04em;
          margin: 0 0 14px 0;
        }
        &_countdown {
          color: #5abc67;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          margin: 0 0 6px 0;
          &Img {
            display: flex;
            align-items: center;
            margin: 0 4px 0 0;
          }
        }
      }
      &__description {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        margin: 20px 0 0 0;
        span {
          color: #EC5656;
        }
      }
      &__balances {
        max-width: 300px;
        width: 100%;
        margin: 0 auto 20px auto;
        @media only screen and (min-width: 768px) {
          max-width: 270px;
        }
        &_total {
          font-size: 14px;
          line-height: 16px;
        }
        .Invoice_inner__subtitle {
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          margin: 0 0 10px 0;
          text-align: left;
        }
        &_inner {
          height: 46px;
          box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
          border-radius: 4px;
          background: #19283a;
          display: flex;
          align-items: center;
          padding: 0 12px;
          position: relative;
        }
      }
      &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: 768px) {
          flex-direction: row;
        }
      }
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        &:nth-child(1) {
          @media only screen and (max-width: 767px) {
            margin: 15px 0 45px 0;
          }
        }
        &:nth-child(2) {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }
        &:nth-child(3) {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }
        @media only screen and (min-width: 768px) {
          width: 33.33%;
        }
      }
      &-amount {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        border: solid 1px #56bbd3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        &-inner {
          margin: 0 0 2px 0;
          span {
            margin: 0 0 0 2px;
          }
        }
        span {
          color: #A6AEBF;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
        }
        img {
          position: absolute;
        }
      }
    }

    .Invoice_inner__border {
      width: 100%;
      margin: 14px 0;
      height: 1px;
      background: #2af3f3;
      opacity: 0.1;
      border-radius: 4px;
    }
  }
}