@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





































.Notification_root {
  margin: 10px;
  .Notification__wrapper {
    padding: 10px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.34);
    border: 1px solid #fff;
    .Notification__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 7px 10px;
      border-radius: 2px;
    }
    &--success {
      .Notification__container {
        background: #5abc67;
      }
    }
    &--error {
      .Notification__container {
        background: #ff556d;
      }
    }
    .Notification__icon {
      display: block;
      flex: 0 1 22px;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 2px;
      position: relative;
      svg {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }
  }
  .Notification__text-wrapper {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    max-width: 250px;
    text-align: center;
    margin: 0 auto;
  }
}
