@import '@/assets/scss/_variables.scss';

.Button_button {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  outline: none;
  border: none;
  cursor: pointer;
  border-radius: $default-border-radius;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.Button_fullWidth {
    width: 100%;
    display: flex;
  }
  &.Button_small {
    font-size: 10px;
    line-height: 12px;
    @media only screen and (min-width: $large-width) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &.Button_large {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    @media only screen and (min-width: $large-width) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &.Button_disabled {
    opacity: 0.2;
    cursor: default;
  }
  &_Icon {
    display: block;
    line-height: 1;
    margin: 0 4px 0 0;

    svg {
      transition: all .25s;
    }
  }
}

.Button_defaultBtn {
  min-width: 84px;
  color: #2af3f3;
  background: rgba(#2af3f3, 0.2);
  // transition: 0.25s all ease-in-out;
  @media only screen and (min-width: $large-width) {
    //     min-width: 130px;
  }
  &.btn-default-small,
  &.Button_small {
    line-height: 30px;
  }
  &.btn-default-large,
  &.Button_large {
    line-height: 44px;
  }
  &:hover {
    background: rgba(#2af3f3, 1);
    color: #2d4d70;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  }
  &:active {
    color: #2af3f3;
    background: rgba(#2af3f3, 0.2);
  }
  &.btn-default-red,
  &.Button_red {
    background: rgba(#ff556d, 0.1);
    color: #ff556d;
    &:active {
      transform: scale(0.95);
    }
    &:hover {
      box-shadow: none;
    }
  }
  &.btn-default-green,
  &.Button_green {
    background: #5ABC67;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }
  &.btn-default-yellow,
  &.Button_yellow {
    background: rgba(#F0C854, .1);
    color: #F0C854;
    &:hover {
      box-shadow: none;
    }
  }
}

// 
.btn-bright,
.btn-bright-light,
.Button_brightBtn {
  background: rgba(#2af3f3, 1);
  color: #2d4d70;
  box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  transition: 0.25s all ease-in-out;
  min-width: 84px;
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (min-width: $large-width) {
    //     min-width: 130px;
  }
  &.btn-bright-small,
  &.Button_small {
    line-height: 30px;
  }
  &.btn-bright-large,
  &.Button_large {
    line-height: 44px;
  }
  &:hover {
    background: #a5ffff;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  }
  &:active {
    background: rgba(#2af3f3, 1);
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  }
}

.Button_arrow {
  &Right {
    display: block;
    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 5px;
        border-color: transparent transparent transparent #2d4d70;
        margin: 0 0 0 6px;
      }
    }
  }
}
