@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
















.littleRedArrow {
  transform: rotate(180deg);
}
